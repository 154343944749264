import React from 'react';
import cookie from 'react-cookies';
import { Card } from 'antd';
import { Col, Row } from 'antd';
import { Typography } from 'antd';
import { Button, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';

const Title = Typography.Title;
function Login() {
    const n = useNavigate();
    const onFinish = (values) => {
        fetch('https://do.kanosaikou.cn/login.php', {
            method: 'POST',
            headers: new Headers({
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json;charset=UTF-8",
            }),
            body: JSON.stringify(values),
        }).then((res) => res.json()).then((res) => {
            if (res.success) {
                const expires = new Date(new Date().getTime() + 24 * 3600 * 1000 * 365);
                cookie.save('token', res.token, { expires });
                cookie.save('username', res.username, { expires });
                n('/');
            } else {
                message.error(res.response);
            }
        });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Row justify={"center"} align={"middle"} className='auth'>
            <Col lg={12} md={18} sm={22} xs={23}>
                <Card>
                    <Title level={2} style={{ textAlign: "center", marginTop: 0, marginBottom: '50px' }}>登录</Title>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 18,
                        }}
                        size='large'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="用户名"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入用户名！',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="密码"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入密码！',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{
                            sm: {
                                offset: 4,
                                span: 18,
                            },
                            lg: {
                                offset: 4,
                                span: 18,
                            },
                            md: {
                                offset: 4,
                                span: 18,
                            },
                            xs:{
                                offset: 0,
                                span: 24,
                            }
                        }}>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                            或者 <a href="/register">注册一个账号!</a>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
}

export default Login;
