import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { Button, Card, Timeline } from 'antd';
import { Col, Row } from 'antd';
import { Layout, Menu } from 'antd';
import { Typography, Space, Modal } from 'antd';
import { Form, Input } from 'antd';
import Icon, {
    SettingOutlined,
    LogoutOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import TextArea from 'antd/es/input/TextArea';
const { Header } = Layout;
const { Title, Text } = Typography;
const items = [
    {
        label: cookie.load('username'),
        key: 'Menu',
        icon: <SettingOutlined />,
        children: [
            {
                label: '登出',
                key: 'Logout',
                icon: <LogoutOutlined />,
            },
        ],
    },
];
/* 
    0->学习
    1->睡觉
    2->吃饭
    3->运动
    4->玩
    5->自定义
*/
const StudySvg = () => (
    <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 1024 1024'>
        <path d='M593.8 882.2H481.9l-9.6-26.3c-3.9-10.6-33.1-21.6-75.5-21.6H262.4c-56.9 0-98.5-14.5-123.8-43.1-23.1-26.2-24-54.5-23.4-65.2V257.3c0-45.2 19.4-71.9 35.6-86.4 37.1-33.1 88.9-31.3 102.4-30.1h181c38.4 0 71.3 12.6 95.1 36.4 8.6-8.5 18.6-16 29.8-22.2 31.7-17.6 63-19.3 66.5-19.4H808.9c44.2 0 71.2 18.1 86.1 33.2 33.3 33.9 34.3 80 34.3 87.3v456.8c0 50.4-20.2 78.5-37.1 93.2-31.6 27.4-71.1 26.5-83.6 25.4H651.1c-38.5 0-47.9 17.5-49.7 22l-7.6 28.7z m-61.3-80h6.7c3.2-4.4 7-9.1 11.6-13.6 16.9-16.9 48.1-37 100.3-37H814l1.9 0.4c4.4 0.4 16.9 0 23.9-6.3 7.7-6.9 9.3-21.6 9.3-32.6V255.6c-0.1-3.6-1.3-14.6-6.2-23.7-4.4-8.1-11.9-16.3-34.2-16.3H628.5c-3.2 0.3-18.4 2.1-32.4 10.4-11.2 6.7-26.2 19.5-26.3 53.3v0.6l-80-0.3c0-0.9-0.5-18.1-8.5-33.7-6-11.7-16.8-25-47.1-25H248.7l-1.9-0.3c-7.6-0.7-31.2-0.3-42.9 10.2-2.1 1.9-8.7 7.8-8.7 26.5V730l-0.1 0.9c0.7 11.3 21.8 23.4 67.2 23.4h133.4c23.3-0.1 45.1 2.3 64.6 7 36.7 8.7 58.8 24.5 72.2 40.9z m-337.3-71.8z'></path>
        <path d='M489.1 232h80v343h-80z'></path>
    </svg>
);

const SleepSvg = () => (
    <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 1024 1024'>
        <path d='M416.917333 64A405.632 405.632 0 0 0 405.333333 160.554667c0 223.850667 181.482667 405.333333 405.333334 405.333333 52.181333 0 102.058667-9.856 147.861333-27.818667C940.16 768.576 747.242667 949.888 512 949.888c-247.424 0-448-200.576-448-448C64 287.082667 215.168 107.605333 416.917333 64z m-75.562666 93.717333l-1.856 0.938667C212.885333 222.336 128 353.472 128 501.909333c0 212.053333 171.925333 383.978667 384 383.978667 167.104 0 310.485333-107.178667 362.816-257.813333l0.896-2.709334-6.336 0.874667c-15.488 1.941333-31.125333 3.114667-46.869333 3.498667l-11.84 0.149333c-256.618667 0-465.130667-205.952-469.269334-461.568L341.333333 160.554667l0.021334-2.837334zM746.666667 139.221333a32 32 0 0 1 32 32v64h64a32 32 0 0 1 0 64h-64v64a32 32 0 0 1-64 0v-64h-64a32 32 0 0 1 0-64h64v-64a32 32 0 0 1 32-32z' />
    </svg>
);

const DietSvg = () => (
    <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 1024 1024'>
        <path d='M293.3 958.8c-24.7 0-44.6-20-44.6-44.6V443.8c0-24.7 20-44.6 44.6-44.6 24.7 0 44.6 20 44.6 44.6v470.3c0 24.7-20 44.7-44.6 44.7zM743.1 958.8c-24.7 0-44.6-20-44.6-44.6V443.8c0-24.7 20-44.6 44.6-44.6s44.6 20 44.6 44.6v470.3c0.1 24.7-19.9 44.7-44.6 44.7zM293.3 343.1c-24.7 0-44.6-20-44.6-44.6v-188c0-24.7 20-44.6 44.6-44.6 24.7 0 44.6 20 44.6 44.6v188c0 24.6-20 44.6-44.6 44.6z'></path>
        <path d='M293.3 488.5C185.6 488.5 98 400.9 98 293.2V110.5c0-24.7 20-44.6 44.6-44.6s44.6 20 44.6 44.6v182.7c0 58.4 47.5 106 106 106s106-47.5 106-106V110.5c0-24.7 20-44.6 44.6-44.6s44.6 20 44.6 44.6v182.7c0.1 107.7-87.5 195.3-195.1 195.3zM743.1 488.5c-115.8 0-184.9-63.8-184.9-170.6 0-121.4 57.9-252 184.9-252 112.3 0 184.9 98.9 184.9 252 0 106.8-69.1 170.6-184.9 170.6z m0-333.3c-70.1 0-95.6 97.3-95.6 162.7 0 37.9 10.9 81.3 95.6 81.3s95.6-43.5 95.6-81.3c0-16.7-2.7-162.7-95.6-162.7z'></path>
    </svg>
);

const SportSvg = () => (
    <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 1024 1024'>
        <path d='M611.4 141.1c-99.1-26.6-202.5-12.9-291.4 38.3-88.8 51.3-152.4 134.1-178.9 233.2-54.8 204.5 67 415.5 271.5 470.3 33.1 8.9 66.7 13.3 100 13.3 66.5 0 132.2-17.5 191.3-51.6 88.8-51.3 152.4-134.1 178.9-233.2C937.7 406.9 815.9 195.9 611.4 141.1zM202.9 429.2c18.3-68.2 57.8-126.9 112.7-169.4 44.7 52.3 63.2 131.4 41.9 210.8-21.2 79.3-76.7 138.6-141.5 161.6C190.5 569.8 184.2 499.1 202.9 429.2zM429.2 821.1c-78.2-21-141.6-69.5-183.2-132.1 80.2-31.9 147.4-105 173.3-201.8 25.9-96.7 4.4-193.6-49.1-261.3 44.5-22.1 92.5-34 141.2-34 27.8 0 55.8 3.7 83.4 11 78.2 21 141.6 69.5 183.2 132.1-80.2 31.9-147.4 105-173.3 201.8-26 96.9-4.3 193.8 49.3 261.5C584.2 833.1 505.1 841.5 429.2 821.1zM821.1 594.8c-18.3 68.2-57.8 126.9-112.7 169.4-44.7-52.3-63.2-131.4-41.9-210.8 21.2-79.3 76.7-138.6 141.5-161.6C833.5 454.2 839.8 524.9 821.1 594.8z' />
    </svg>
);

const PlaySvg = () => (
    <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 1024 1024'>
        <path d='M378.481778 227.555556C217.201778 227.555556 85.333333 360.647111 85.333333 526.222222 85.333333 691.825778 217.258667 824.888889 378.481778 824.888889a12.145778 12.145778 0 0 0 11.52-8.334222l2.616889-7.879111A142.08 142.08 0 0 1 527.559111 711.111111h40.760889a42.666667 42.666667 0 1 1 0 85.333333h-40.760889c-24.462222 0-46.222222 15.701333-53.873778 38.968889l-2.588444 7.850667A97.507556 97.507556 0 0 1 378.424889 910.222222C168.817778 910.222222 0 737.649778 0 526.222222 0 314.823111 168.846222 142.222222 378.481778 142.222222h267.036444C855.210667 142.222222 1024 314.823111 1024 526.222222 1024 737.649778 855.210667 910.222222 645.518222 910.222222a42.666667 42.666667 0 1 1 0-85.333333C806.798222 824.888889 938.666667 691.825778 938.666667 526.222222 938.666667 360.647111 806.741333 227.555556 645.518222 227.555556h-267.064889 0.056889z'></path>
        <path d='M327.111111 373.845333a42.666667 42.666667 0 0 1 42.666667 42.666667V483.555556h66.360889a42.666667 42.666667 0 0 1 0 85.333333h-66.332445v67.043555a42.666667 42.666667 0 0 1-85.333333 0V568.888889H218.026667a42.666667 42.666667 0 0 1 0-85.333333h66.389333v-67.043556a42.666667 42.666667 0 0 1 42.666667-42.666667h0.056889z m381.610667 0a42.666667 42.666667 0 0 1 42.666666 42.666667v24.376889a42.666667 42.666667 0 0 1-85.333333 0v-24.376889a42.666667 42.666667 0 0 1 42.666667-42.666667z m0 195.043556a42.666667 42.666667 0 0 1 42.666666 42.666667v24.376888a42.666667 42.666667 0 0 1-85.333333 0V611.555556a42.666667 42.666667 0 0 1 42.666667-42.666667z'></path>
    </svg>
);

const CustomSvg = () => (
    <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 1024 1024'>
        <path d='M733.653 918.642c-12.368 0-25.232-2.968-36.116-8.905l-184.043-96.474-183.548 96.969c-11.379 5.937-23.747 8.905-36.611 8.905-15.337 0-30.674-4.453-43.042-12.863-23.747-16.326-36.116-44.032-31.169-71.737l35.621-209.274L99.894 473.872c-20.284-19.79-27.211-47.99-17.811-74.211 9.4-26.221 32.158-44.526 60.358-48.484L353.2 320.503l91.527-186.022C457.095 109.25 484.305 92.924 513 92.924c29.19 0 55.905 16.326 68.274 41.558L672.8 320.503l210.758 30.674c28.2 3.958 51.453 22.758 60.358 48.484 9.4 26.221 2.474 54.916-17.811 74.705L771.253 625.262l35.621 209.274c4.947 27.705-7.421 55.411-31.169 71.737-11.873 7.916-26.715 12.369-42.052 12.369zM513.495 743.504c8.905 0 17.316 1.979 25.232 5.937l190.969 100.432c0.989 0.495 2.474 0.99 3.958 0.99 1.979 0 3.463-0.495 4.947-1.484s2.474-2.968 2.474-4.453l-37.105-217.19c-2.968-16.821 2.969-34.137 14.842-46.011l160.295-156.337c0.989-0.989 1.979-2.474 1.484-4.453-0.495-1.484-2.968-3.463-5.937-3.958L655.98 385.314c-17.316-2.474-32.653-13.358-40.074-29.19l-94.99-192.948c-0.989-1.979-3.958-3.463-6.926-3.463s-5.937 1.484-6.926 3.958l-94.99 192.948c-7.916 15.832-22.758 26.221-40.074 29.19l-219.664 31.663c-2.968 0.495-5.442 2.474-5.937 3.958-0.495 1.484 0 2.968 1.484 4.453l160.295 156.832c12.368 11.874 17.811 29.19 14.842 46.011l-37.105 216.695c-0.495 1.484 0.495 3.463 2.474 4.947 1.484 0.989 2.968 1.484 4.947 1.484 1.484 0 2.968-0.495 4.453-0.989l190.474-100.432c7.916-4.453 16.326-6.927 25.232-6.927z'></path>
    </svg>
);

const StudyIcon = (props) => <Icon component={StudySvg} {...props} />;
const SleepIcon = (props) => <Icon component={SleepSvg} {...props} />;
const DietIcon = (props) => <Icon component={DietSvg} {...props} />;
const SportIcon = (props) => <Icon component={SportSvg} {...props} />;
const PlayIcon = (props) => <Icon component={PlaySvg} {...props} />;
const CustomIcon = (props) => <Icon component={CustomSvg} {...props} />;

const events = [
    {
        className: 'bg-aqua',
        icon: <StudyIcon />,
        showText: '学习',
    },
    {
        className: 'bg-grey',
        icon: <SleepIcon />,
        showText: '睡觉',
    },
    {
        className: 'bg-purple',
        icon: <DietIcon />,
        showText: '吃饭',
    },
    {
        className: 'bg-pink',
        icon: <SportIcon />,
        showText: '运动',
    },
    {
        className: 'bg-orange',
        icon: <PlayIcon />,
        showText: '娱乐',
    },
    {
        className: 'bg-blue',
        icon: <CustomIcon />,
        showText: '自定义',
    },
];

function ActivityCard({ data }) {
    return (
        <Card className={events[data[2]].className}>
            <Row wrap={false}>
                <Col
                    flex='70px'
                    style={{
                        textAlign: 'center',
                        fontSize: '50px',
                    }}
                >
                    {events[data[2]].icon}
                </Col>
                <Col flex='auto'>
                    <Space direction='vertical' size={1}>
                        <Title
                            level={3}
                            style={{
                                marginTop: 10,
                                marginBottom: 0,
                                wordBreak: 'break-all',
                            }}
                            className={events[data[2]].className}
                        >
                            {data[3]}
                        </Title>
                        <Text
                            className={events[data[2]].className}
                            style={{
                                margin: 0,
                                wordBreak: 'break-all',
                            }}
                        >
                            {data[4]}
                        </Text>
                        <Text
                            type='secondary'
                            className={events[data[2]].className}
                        >
                            开始时间：{data[5]}
                        </Text>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}
const fetchEvent = (...args) =>
    fetch(...args).then((res) => res.json().then((res) => res));
function CardList({ showAdd }) {
    const token = cookie.load('token');
    const { data, error, isLoading } = useSWR(
        'https://do.kanosaikou.cn/getEvent.php?token=' + token,
        fetchEvent,
        { refreshInterval: 3000 }
    );
    if (error) return <div>加载出错</div>;
    if (isLoading) return <LoadingOutlined />;
    if (data.length === 0) {
        return (
            <span>
                <Title
                    level={3}
                    style={{
                        wordBreak: 'break-all',
                    }}
                >
                    添加事件
                </Title>
                <Card>
                    {events.map((e, index) => {
                        return (
                            <Button
                                key={index}
                                type='text'
                                style={{ fontSize: 20, height: '50px' }}
                                onClick={() => showAdd(index)}
                            >
                                {e.icon}
                                {e.showText}
                            </Button>
                        );
                    })}
                </Card>
            </span>
        );
    }
    const now = data[0];
    const history = data.slice(1);
    let timeLineItems = [];
    let timeSplit = [];
    if (history.length > 1) {
        let lastIndex = 0;
        let lastTime = new Date(history[0][5]).toLocaleDateString();
        for (let i = 1; i < history.length; i++) {
            let thisTime = new Date(history[i][5]).toLocaleDateString();

            if (thisTime !== lastTime) {
                console.log(i);
                timeSplit = history.slice(lastIndex, i);
                timeLineItems.push({
                    children: (
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <Title
                                level={3}
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    wordBreak: 'break-all',
                                }}
                            >
                                {new Date(timeSplit[0][5]).toLocaleDateString()}
                            </Title>
                            {timeSplit.map((item, index) => {
                                return (
                                    <ActivityCard key={item[0]} data={item} />
                                );
                            })}
                        </Space>
                    ),
                });
                lastIndex = i;
                lastTime = thisTime;
            }
        }
        timeSplit = history.slice(lastIndex);
        timeLineItems.push({
            children: (
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Title
                        level={3}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            wordBreak: 'break-all',
                        }}
                    >
                        {new Date(timeSplit[0][5]).toLocaleDateString()}
                    </Title>
                    {timeSplit.map((item, index) => {
                        return <ActivityCard key={item[0]} data={item} />;
                    })}
                </Space>
            ),
        });
    }
    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Title
                level={3}
                style={{
                    wordBreak: 'break-all',
                }}
            >
                当前正在
            </Title>
            <ActivityCard key={now[0]} data={now} />
            <Title
                level={3}
                style={{
                    wordBreak: 'break-all',
                }}
            >
                添加事件
            </Title>
            <Card>
                {events.map((e, index) => {
                    return (
                        <Button
                            key={index}
                            type='text'
                            style={{ fontSize: 20, height: '50px' }}
                            onClick={() => showAdd(index)}
                        >
                            {e.icon}
                            {e.showText}
                        </Button>
                    );
                })}
            </Card>
            <Title
                level={3}
                style={{
                    wordBreak: 'break-all',
                }}
            >
                历史记录
            </Title>

            {history.length === 0 ? (
                <div>暂无历史记录</div>
            ) : (
                <Timeline items={timeLineItems}></Timeline>
            )}
        </Space>
    );
}

function Dashboard() {
    const { mutate } = useSWRConfig();
    const [form] = Form.useForm();
    const n = useNavigate();
    const handleMenu = (e) => {
        cookie.remove('token');
        n('/login');
    };
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [nowIndex, setNowIndex] = useState(0);
    const showModal = (index) => {
        setNowIndex(index);
        setOpen(true);
    };
    const handleOk = () => {
        form.validateFields()
            .then((v) => {
                console.log(JSON.stringify(v));
                setConfirmLoading(true);
                v.token = cookie.load('token');
                v.tid = nowIndex;
                fetch('https://do.kanosaikou.cn/newEvent.php', {
                    method: 'POST',
                    headers: new Headers({
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json;charset=UTF-8',
                    }),
                    body: JSON.stringify(v),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.success) {
                            setOpen(false);
                            setConfirmLoading(false);
                            mutate(
                                'https://do.kanosaikou.cn/getEvent.php?token=' +
                                    v.token
                            );
                        } else {
                            setConfirmLoading(false);
                        }
                    });
            })
            .catch((e) => console.log('Error'));
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    useEffect(() => {
        const auth = cookie.load('token');
        if (auth === undefined) {
            n('/login');
        }
    }, []);
    return (
        <main>
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
                className='bg-white'
            >
                <div className='demo-logo' />
                <Menu
                    onClick={handleMenu}
                    mode='horizontal'
                    defaultSelectedKeys={['2']}
                    items={items}
                    style={{ minWidth: '200px', justifyContent: 'flex-end' }}
                />
            </Header>
            <Row
                align={'middle'}
                justify={'center'}
                style={{ marginTop: '10px' }}
            >
                <Col lg={14} md={18} sm={22} xs={23}>
                    <CardList showAdd={showModal} />
                </Col>
            </Row>
            <Modal
                title='添加事件'
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText='确认'
                cancelText='取消'
            >
                <p>
                    事件类型：{events[nowIndex].icon}
                    {events[nowIndex].showText}
                </p>
                <Form
                    name='basic'
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    form={form}
                >
                    <Form.Item
                        label='名称'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: '请输入名称！',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label='描述' name='description'>
                        <TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </main>
    );
}

export default Dashboard;
